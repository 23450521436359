var tempUrl = '',
    tempElement,
    player,
    playerPause = false,
    playerMuted = false,
    playerVolume = localStorage.getItem('playerVolume') ? localStorage.getItem('playerVolume') : 50,
    playerVolumeOld = playerVolume,
    playerController = $('.player__controller'),
    playerVolumeButton = $('.player__button');

function playerPlay (url, element) {

    if (playerPause) {
        playerPause = false;
        player.play();
    } else {
        if (url !== tempUrl || element !== tempElement) {
            $(tempElement).removeClass('player__play_active');
            $(tempElement).parents('.player').find('.player__rewind').val(0);

            if (player) player.stop();

            player = soundManager.createSound({
                url: url,
                autoLoad: true,
                autoPlay: false,
                onload: function() {
                    tempUrl = url;
                    tempElement = element;
                },
                whileplaying: function() {
                    if(this.position/this.duration * 100) $(element).parents('.player').find('.player__rewind').val(this.position/this.duration * 100);
                },
                onfinish: function() {
                    $(element).parents('.player').find('.player__rewind').val(0);
                    $(element).removeClass('player__play_active');

                    player.stop();
                },
                volume: playerVolume
            });

            player.play();
        } else {
            playerPause = true;
            player.pause();
        }
    }

    $(element).toggleClass('player__play_active');

}

function toggleMute () {

    if (!playerMuted) {
        playerMuted = true;
        playerVolumeOld = playerVolume;
        playerVolume = 0;
        if (player) player.setVolume(playerVolume);
    } else {
        playerMuted = false;
        playerVolume = playerVolumeOld == 0 ? 10 : playerVolumeOld;
        if (player) player.setVolume(playerVolume);
    }

    changeVolume(playerVolume);
}

function changeVolumeClass (value) {
    if (value == 0 || playerMuted) {
        playerVolumeButton.addClass('player__button_mute');
    } else {
        playerVolumeButton.removeClass('player__button_mute');
    }

    if (value <= 50 && value > 0 && !playerMuted) {
        playerVolumeButton.removeClass('player__button_mute');
        playerVolumeButton.addClass('player__button_medium');
    } else {
        playerVolumeButton.removeClass('player__button_medium');
    }

    if (value > 50) {
        playerVolumeButton.removeClass('player__button_mute');
        playerVolumeButton.removeClass('player__button_medium');
    }
}

function changeVolume (value) {
    playerVolume = value;
    playerMuted = playerVolume <= 0;
    if (player) player.setVolume(value);

    playerController.val(value);
    playerController.siblings('.player__fill_highlight').css('height', value + '%');

    localStorage.setItem('playerVolume', value);

    changeVolumeClass(value);
}

if (playerController.length) {
    changeVolume(playerVolume);
}

$(document).on('change', '.player__controller', function () {
    changeVolume($(this).val());
});

playerController.on('input', function () {
    $(this).trigger('change');
});
