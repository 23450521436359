$(document).ready(function () {

    var fieldSelect = $('.input_select');
    var fieldList = $('.input__list');

    $(document).on('focus', '.input_select', function () {
        fieldList.slideDown(200);
        $(this).addClass('input_active input_focus');
    });

    $(document).mouseup(function (event) {
        if (fieldList.has(event.target).length === 0 && fieldSelect.has(event.target).length === 0){
            fieldSelect.removeClass('input_focus');
            fieldList.slideUp(200);

            fieldSelect.each(function () {
                if (!$(this).find('.input__field').val() > 0) $(this).removeClass('input_active');
            });
        }
    });

   $(document).on('click', '.input__item', function () {
       $(this).parents('.input_select').removeClass('input_focus').find('.input__field').val($(this).html());
       $(this).parents('.input_select').find('input[type=hidden]').val($(this).data('value'));
       fieldList.slideUp(200);
   });

    var townBusy = false;

   $('.input_town input').on('change', function () {
       var townValue = $(this).val(),
           townList = $(this).parents('.input_town').find('.input__list'),
           townListItems = townList.find('.input__item');

       if (townValue.length >= 3 && !townBusy) {
           var form_data = new FormData();
           form_data.append('_token', $('meta[name = "csrf-token"]').attr('content'));
           form_data.append('town_title', $(this).val());

           townBusy = true;

           $.ajax({
               url: '/api/town/',
               method: 'post',
               data: form_data,
               processData: false,
               contentType: false,
               context : this,
               success : function (response) {
                   townListItems.remove();
                   townList.append(response.towns);

                   setTimeout(function() {
                       townBusy = false;
                   }, 2000);
               }
           });
       }
   }).on('input', function () {
       $(this).trigger('change');
   });

   $(document).on('click', '.input__password', function () {
       var passwordField = $(this).parents('.input').find('.input__field');

       if (passwordField.prop('type') == 'password') {
           passwordField.prop('type', 'text');
           $(this).addClass('input__password_show');
       } else {
           passwordField.prop('type', 'password');
           $(this).removeClass('input__password_show');
       }
   });

    $('.input_date input').inputmask('99.99.9999');
    $('.input_tel input').inputmask('8 999-999-99-99');

    $('input[date]').datepicker({
        position: 'top right',
    });

    $('input[year]').datepicker({
        position: 'top right',
        view: 'years',
        maxDate: new Date(2010, 01, 01),
        startDate: new Date(1990, 01, 01),
    });

    $(document)
        .one('focus.autoExpand', 'textarea.autoExpand', function(){
            var savedValue = this.value;
            this.value = '';
            this.baseScrollHeight = this.scrollHeight;
            this.value = savedValue;
        })
        .on('input.autoExpand', 'textarea.autoExpand', function(){
            var minRows = this.getAttribute('data-min-rows')|0, rows;
            this.rows = minRows;
            rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 19);
            this.rows = minRows + rows;
        });
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});




function isSafari() {
    var chr = window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
    var sfri = window.navigator.userAgent.toLowerCase().indexOf("safari") > -1;
    return !chr && sfri;
}